import AlertCircle from './AlertCircle';
import AnimalBird from './AnimalBird';
import AnimalCat from './AnimalCat';
import AnimalCow from './AnimalCow';
import AnimalDog from './AnimalDog';
import AnimalHorse from './AnimalHorse';
import AnimalMouse from './AnimalMouse';
import AnimalPig from './AnimalPig';
import AnimalSnake from './AnimalSnake';
import Archive from './Archive';
import ArrowCircle from './ArrowCircle';
import ArrowDownRight from './ArrowDownRight';
import ArrowDown from './ArrowDown';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ArrowUp from './ArrowUp';
import ArrowsIn from './ArrowsIn';
import ArrowsOut from './ArrowsOut';
import Article from './Article';
import Award from './Award';
import Bank from './Bank';
import BellCancel from './BellCancel';
import Bell from './Bell';
import Bone from './Bone';
import Bookmark from './Bookmark';
import BulkSelect from './BulkSelect';
import Buttons from './Buttons';
import CalendarCheck from './CalendarCheck';
import CalendarDate from './CalendarDate';
import CalendarInfo from './CalendarInfo';
import CalendarPen from './CalendarPen';
import CalendarPlus from './CalendarPlus';
import CameraOff from './CameraOff';
import Camera from './Camera';
import Cancel from './Cancel';
import CaretDown from './CaretDown';
import CaretUp from './CaretUp';
import CellphoneCancel from './CellphoneCancel';
import Cellphone from './Cellphone';
import ChainLink from './ChainLink';
import ChatBubble from './ChatBubble';
import CheckmarkBox from './CheckmarkBox';
import CheckmarkCircle from './CheckmarkCircle';
import Checkmark from './Checkmark';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Clinic from './Clinic';
import Clock from './Clock';
import CloseCircleSolid from './CloseCircleSolid';
import CloseCircle from './CloseCircle';
import Close from './Close';
import Code from './Code';
import CreditCardLogoAmex from './CreditCardLogoAmex';
import CreditCardLogoDiners from './CreditCardLogoDiners';
import CreditCardLogoDiscover from './CreditCardLogoDiscover';
import CreditCardLogoGeneric from './CreditCardLogoGeneric';
import CreditCardLogoJcb from './CreditCardLogoJcb';
import CreditCardLogoMastercard from './CreditCardLogoMastercard';
import CreditCardLogoUnionpay from './CreditCardLogoUnionpay';
import CreditCardLogoVisa from './CreditCardLogoVisa';
import CreditCard from './CreditCard';
import Cut from './Cut';
import DollarCircle from './DollarCircle';
import Dollar from './Dollar';
import DotsHorizontal from './DotsHorizontal';
import DotsVertical from './DotsVertical';
import DoubleArrowCircle from './DoubleArrowCircle';
import Download from './Download';
import EmailSend from './EmailSend';
import EnvelopeCancel from './EnvelopeCancel';
import Envelope from './Envelope';
import ExternalLink from './ExternalLink';
import EyeOpen from './EyeOpen';
import EyeSlash from './EyeSlash';
import Eyedropper from './Eyedropper';
import FileHealth from './FileHealth';
import Filter from './Filter';
import Form from './Form';
import Gear from './Gear';
import Gif from './Gif';
import Grid from './Grid';
import Hashtag from './Hashtag';
import Health from './Health';
import HeartSolid from './HeartSolid';
import Heart from './Heart';
import House from './House';
import Image from './Image';
import InfoCircle from './InfoCircle';
import Input from './Input';
import Invoice from './Invoice';
import KanbanBoard from './KanbanBoard';
import List from './List';
import Loader from './Loader';
import LocationMarker from './LocationMarker';
import Lock from './Lock';
import Logo from './Logo';
import Loudspeaker from './Loudspeaker';
import Magic from './Magic';
import MagnifyingGlass from './MagnifyingGlass';
import Mailbox from './Mailbox';
import Menu from './Menu';
import MessageDetail from './MessageDetail';
import MessageWarning from './MessageWarning';
import Message from './Message';
import Messages from './Messages';
import MicrophoneMute from './MicrophoneMute';
import Microphone from './Microphone';
import Minus from './Minus';
import MoveDown from './MoveDown';
import MoveLeft from './MoveLeft';
import MoveRight from './MoveRight';
import MoveUp from './MoveUp';
import Notification from './Notification';
import Package from './Package';
import Paperclip from './Paperclip';
import Pause from './Pause';
import Paw from './Paw';
import PenSquare from './PenSquare';
import Pen from './Pen';
import PersonAdd from './PersonAdd';
import PersonCircle from './PersonCircle';
import Person from './Person';
import PhoneButtons from './PhoneButtons';
import PhoneCancel from './PhoneCancel';
import Phone from './Phone';
import Pill from './Pill';
import PinCancel from './PinCancel';
import Pin from './Pin';
import Play from './Play';
import Plus from './Plus';
import Popup from './Popup';
import Printer from './Printer';
import QuestionMarkCircleSolid from './QuestionMarkCircleSolid';
import QuestionMarkCircle from './QuestionMarkCircle';
import QuestionMark from './QuestionMark';
import Record from './Record';
import Report from './Report';
import Ribbon from './Ribbon';
import Robot from './Robot';
import Send from './Send';
import Sliders from './Sliders';
import SmileyFace from './SmileyFace';
import Sort from './Sort';
import StarCircle from './StarCircle';
import StarSolid from './StarSolid';
import Star from './Star';
import StopRecording from './StopRecording';
import Stop from './Stop';
import Support from './Support';
import Table from './Table';
import Tag from './Tag';
import TargetSolid from './TargetSolid';
import Target from './Target';
import TaskList from './TaskList';
import Team from './Team';
import ThumbsDown from './ThumbsDown';
import ThumbsUp from './ThumbsUp';
import Transfer from './Transfer';
import Trashcan from './Trashcan';
import TwoSquares from './TwoSquares';
import Upload from './Upload';
import VideoCamera from './VideoCamera';
import WarningSign from './WarningSign';
import Website from './Website';
import Wrench from './Wrench';
// These types are generated from the svgrIconTemplate.js file
export type IconName =
  | 'alertCircle'
  | 'animalBird'
  | 'animalCat'
  | 'animalCow'
  | 'animalDog'
  | 'animalHorse'
  | 'animalMouse'
  | 'animalPig'
  | 'animalSnake'
  | 'archive'
  | 'arrowCircle'
  | 'arrowDownRight'
  | 'arrowDown'
  | 'arrowLeft'
  | 'arrowRight'
  | 'arrowUp'
  | 'arrowsIn'
  | 'arrowsOut'
  | 'article'
  | 'award'
  | 'bank'
  | 'bellCancel'
  | 'bell'
  | 'bone'
  | 'bookmark'
  | 'bulkSelect'
  | 'buttons'
  | 'calendarCheck'
  | 'calendarDate'
  | 'calendarInfo'
  | 'calendarPen'
  | 'calendarPlus'
  | 'cameraOff'
  | 'camera'
  | 'cancel'
  | 'caretDown'
  | 'caretUp'
  | 'cellphoneCancel'
  | 'cellphone'
  | 'chainLink'
  | 'chatBubble'
  | 'checkmarkBox'
  | 'checkmarkCircle'
  | 'checkmark'
  | 'chevronDown'
  | 'chevronLeft'
  | 'chevronRight'
  | 'chevronUp'
  | 'clinic'
  | 'clock'
  | 'closeCircleSolid'
  | 'closeCircle'
  | 'close'
  | 'code'
  | 'creditCardLogoAmex'
  | 'creditCardLogoDiners'
  | 'creditCardLogoDiscover'
  | 'creditCardLogoGeneric'
  | 'creditCardLogoJcb'
  | 'creditCardLogoMastercard'
  | 'creditCardLogoUnionpay'
  | 'creditCardLogoVisa'
  | 'creditCard'
  | 'cut'
  | 'dollarCircle'
  | 'dollar'
  | 'dotsHorizontal'
  | 'dotsVertical'
  | 'doubleArrowCircle'
  | 'download'
  | 'emailSend'
  | 'envelopeCancel'
  | 'envelope'
  | 'externalLink'
  | 'eyeOpen'
  | 'eyeSlash'
  | 'eyedropper'
  | 'fileHealth'
  | 'filter'
  | 'form'
  | 'gear'
  | 'gif'
  | 'grid'
  | 'hashtag'
  | 'health'
  | 'heartSolid'
  | 'heart'
  | 'house'
  | 'image'
  | 'infoCircle'
  | 'input'
  | 'invoice'
  | 'kanbanBoard'
  | 'list'
  | 'loader'
  | 'locationMarker'
  | 'lock'
  | 'logo'
  | 'loudspeaker'
  | 'magic'
  | 'magnifyingGlass'
  | 'mailbox'
  | 'menu'
  | 'messageDetail'
  | 'messageWarning'
  | 'message'
  | 'messages'
  | 'microphoneMute'
  | 'microphone'
  | 'minus'
  | 'moveDown'
  | 'moveLeft'
  | 'moveRight'
  | 'moveUp'
  | 'notification'
  | 'package'
  | 'paperclip'
  | 'pause'
  | 'paw'
  | 'penSquare'
  | 'pen'
  | 'personAdd'
  | 'personCircle'
  | 'person'
  | 'phoneButtons'
  | 'phoneCancel'
  | 'phone'
  | 'pill'
  | 'pinCancel'
  | 'pin'
  | 'play'
  | 'plus'
  | 'popup'
  | 'printer'
  | 'questionMarkCircleSolid'
  | 'questionMarkCircle'
  | 'questionMark'
  | 'record'
  | 'report'
  | 'ribbon'
  | 'robot'
  | 'send'
  | 'sliders'
  | 'smileyFace'
  | 'sort'
  | 'starCircle'
  | 'starSolid'
  | 'star'
  | 'stopRecording'
  | 'stop'
  | 'support'
  | 'table'
  | 'tag'
  | 'targetSolid'
  | 'target'
  | 'taskList'
  | 'team'
  | 'thumbsDown'
  | 'thumbsUp'
  | 'transfer'
  | 'trashcan'
  | 'twoSquares'
  | 'upload'
  | 'videoCamera'
  | 'warningSign'
  | 'website'
  | 'wrench';
export const IconMap: Record<IconName, any> = {
  alertCircle: AlertCircle,
  animalBird: AnimalBird,
  animalCat: AnimalCat,
  animalCow: AnimalCow,
  animalDog: AnimalDog,
  animalHorse: AnimalHorse,
  animalMouse: AnimalMouse,
  animalPig: AnimalPig,
  animalSnake: AnimalSnake,
  archive: Archive,
  arrowCircle: ArrowCircle,
  arrowDownRight: ArrowDownRight,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowUp: ArrowUp,
  arrowsIn: ArrowsIn,
  arrowsOut: ArrowsOut,
  article: Article,
  award: Award,
  bank: Bank,
  bellCancel: BellCancel,
  bell: Bell,
  bone: Bone,
  bookmark: Bookmark,
  bulkSelect: BulkSelect,
  buttons: Buttons,
  calendarCheck: CalendarCheck,
  calendarDate: CalendarDate,
  calendarInfo: CalendarInfo,
  calendarPen: CalendarPen,
  calendarPlus: CalendarPlus,
  cameraOff: CameraOff,
  camera: Camera,
  cancel: Cancel,
  caretDown: CaretDown,
  caretUp: CaretUp,
  cellphoneCancel: CellphoneCancel,
  cellphone: Cellphone,
  chainLink: ChainLink,
  chatBubble: ChatBubble,
  checkmarkBox: CheckmarkBox,
  checkmarkCircle: CheckmarkCircle,
  checkmark: Checkmark,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  clinic: Clinic,
  clock: Clock,
  closeCircleSolid: CloseCircleSolid,
  closeCircle: CloseCircle,
  close: Close,
  code: Code,
  creditCardLogoAmex: CreditCardLogoAmex,
  creditCardLogoDiners: CreditCardLogoDiners,
  creditCardLogoDiscover: CreditCardLogoDiscover,
  creditCardLogoGeneric: CreditCardLogoGeneric,
  creditCardLogoJcb: CreditCardLogoJcb,
  creditCardLogoMastercard: CreditCardLogoMastercard,
  creditCardLogoUnionpay: CreditCardLogoUnionpay,
  creditCardLogoVisa: CreditCardLogoVisa,
  creditCard: CreditCard,
  cut: Cut,
  dollarCircle: DollarCircle,
  dollar: Dollar,
  dotsHorizontal: DotsHorizontal,
  dotsVertical: DotsVertical,
  doubleArrowCircle: DoubleArrowCircle,
  download: Download,
  emailSend: EmailSend,
  envelopeCancel: EnvelopeCancel,
  envelope: Envelope,
  externalLink: ExternalLink,
  eyeOpen: EyeOpen,
  eyeSlash: EyeSlash,
  eyedropper: Eyedropper,
  fileHealth: FileHealth,
  filter: Filter,
  form: Form,
  gear: Gear,
  gif: Gif,
  grid: Grid,
  hashtag: Hashtag,
  health: Health,
  heartSolid: HeartSolid,
  heart: Heart,
  house: House,
  image: Image,
  infoCircle: InfoCircle,
  input: Input,
  invoice: Invoice,
  kanbanBoard: KanbanBoard,
  list: List,
  loader: Loader,
  locationMarker: LocationMarker,
  lock: Lock,
  logo: Logo,
  loudspeaker: Loudspeaker,
  magic: Magic,
  magnifyingGlass: MagnifyingGlass,
  mailbox: Mailbox,
  menu: Menu,
  messageDetail: MessageDetail,
  messageWarning: MessageWarning,
  message: Message,
  messages: Messages,
  microphoneMute: MicrophoneMute,
  microphone: Microphone,
  minus: Minus,
  moveDown: MoveDown,
  moveLeft: MoveLeft,
  moveRight: MoveRight,
  moveUp: MoveUp,
  notification: Notification,
  package: Package,
  paperclip: Paperclip,
  pause: Pause,
  paw: Paw,
  penSquare: PenSquare,
  pen: Pen,
  personAdd: PersonAdd,
  personCircle: PersonCircle,
  person: Person,
  phoneButtons: PhoneButtons,
  phoneCancel: PhoneCancel,
  phone: Phone,
  pill: Pill,
  pinCancel: PinCancel,
  pin: Pin,
  play: Play,
  plus: Plus,
  popup: Popup,
  printer: Printer,
  questionMarkCircleSolid: QuestionMarkCircleSolid,
  questionMarkCircle: QuestionMarkCircle,
  questionMark: QuestionMark,
  record: Record,
  report: Report,
  ribbon: Ribbon,
  robot: Robot,
  send: Send,
  sliders: Sliders,
  smileyFace: SmileyFace,
  sort: Sort,
  starCircle: StarCircle,
  starSolid: StarSolid,
  star: Star,
  stopRecording: StopRecording,
  stop: Stop,
  support: Support,
  table: Table,
  tag: Tag,
  targetSolid: TargetSolid,
  target: Target,
  taskList: TaskList,
  team: Team,
  thumbsDown: ThumbsDown,
  thumbsUp: ThumbsUp,
  transfer: Transfer,
  trashcan: Trashcan,
  twoSquares: TwoSquares,
  upload: Upload,
  videoCamera: VideoCamera,
  warningSign: WarningSign,
  website: Website,
  wrench: Wrench,
};
